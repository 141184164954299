var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[(_vm.productImage)?_c('v-col',{attrs:{"sm":"6","cols":"12"}},[_c('div',{staticClass:"product-image"},[_c('img',{staticClass:"product-image-item",attrs:{"src":_vm.productImage,"alt":_vm.productName}})])]):_vm._e(),_c('v-col',{staticClass:"cart-order",attrs:{"sm":"6","cols":"12"}},[_c('v-row',_vm._b({},'v-row',{
          justify: 'center',
          align: 'start',
          'no-gutters': true,
        },false),[_c('v-col',[_c('h3',[_vm._v(_vm._s(_vm.productName))]),_c('div',[_vm._v(" SKU: "),_c('span',[_vm._v(_vm._s(_vm.productSKU))])])]),_c('v-col',{staticClass:"text-right price-text font-bold",attrs:{"cols":"auto"}},[_vm._v(" ฿"+_vm._s(_vm._f("currency2")(_vm.productPrice))+" ")])],1),(_vm.bundles.length > 0)?_c('hr',{staticClass:"my-3 gray"}):_vm._e(),(_vm.bundles.length > 0)?_c('v-row',_vm._b({},'v-row',{
          justify: 'between',
          align: 'center',
        },false),[_c('v-col',[_c('h3',[_vm._v("รายการสินค้าเพิ่มเติม")])])],1):_vm._e(),_vm._l((_vm.bundles),function(item,index){return _c('v-row',_vm._b({key:index,staticClass:"bundle-list"},'v-row',{
          justify: 'between',
        },false),[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"sku-product"},[_c('img',{attrs:{"src":item.image_url}}),_c('div',[_vm._v(_vm._s(item.name))])])]),(+item.price_srp === 0)?[_c('v-col',{staticClass:"price-bundle-text font-bold free"},[_c('div',[_vm._v("ฟรี")])])]:[_c('v-col',{staticClass:"price-bundle-text font-bold",attrs:{"cols":"4"}},[(_vm.isDelivery)?_c('div',[_vm._v(" ฿"+_vm._s(_vm._f("currency2")(item.price_ship))+" ")]):_c('div',[_vm._v("฿"+_vm._s(_vm._f("currency2")(item.price_store)))]),(
                _vm.isDelivery && item.price_ship !== item.price_srp
              )?_c('div',{staticClass:"text-line-through normal-price"},[_vm._v(" ฿"+_vm._s(_vm._f("currency2")(item.price_srp))+" ")]):(
                !_vm.isDelivery && item.price_store !== item.price_srp
              )?_c('div',{staticClass:"text-line-through normal-price"},[_vm._v(" ฿"+_vm._s(_vm._f("currency2")(item.price_srp))+" ")]):_vm._e()])]],2)}),(_vm.isShowPayment)?[_c('hr',{staticClass:"my-3 gray"}),(!_vm.isDeposit)?_c('v-row',_vm._b({},'v-row',{
            justify: 'between',
            align: 'center',
          },false),[_c('v-col',[_c('h3',[_vm._v("ยอดรวมสุทธิ")])]),_c('v-col',{staticClass:"text-right"},[_c('h3',{staticClass:"price-normal-text"},[_vm._v(" ฿"+_vm._s(_vm._f("currency2")(_vm.finalPrice))+" ")])])],1):_vm._e(),(_vm.discountAmount > 0)?_c('v-row',_vm._b({},'v-row',{
            justify: 'between',
            align: 'center',
          },false),[_c('v-col',[_c('h3',[_vm._v("ส่วนลด")])]),_c('v-col',{staticClass:"text-right"},[_c('h3',{staticClass:"price-normal-text"},[_vm._v(" - ฿"+_vm._s(_vm._f("currency2")(_vm.discountAmount))+" ")])])],1):_vm._e(),(_vm.additionalDiscount > 0)?_c('v-row',_vm._b({},'v-row',{
            justify: 'between',
            align: 'center',
          },false),[_c('v-col',[_c('h3',[_vm._v(" "+_vm._s(_vm.additionalDiscountLabel)+" ")])]),_c('v-col',{staticClass:"text-right"},[_c('h3',{staticClass:"price-normal-text"},[_vm._v(" - ฿"+_vm._s(_vm._f("currency2")(_vm.additionalDiscount))+" ")])])],1):_vm._e(),(_vm.couponCode)?_c('v-row',_vm._b({},'v-row',{
            justify: 'between',
            align: 'center',
          },false),[_c('v-col',[_c('h3',[_vm._v("คูปองที่ใช้")])]),_c('v-col',{staticClass:"text-right"},[_c('h3',{staticClass:"badge-coupon"},[_vm._v(" "+_vm._s(_vm.couponCode)+" ")])])],1):_vm._e(),(_vm.advancePaymentOptional > 0)?_c('v-row',_vm._b({},'v-row',{
            justify: 'between',
            align: 'center',
          },false),[_c('v-col',[_c('h3',[_vm._v(" ชำระเพิ่มเติม ")])]),_c('v-col',{staticClass:"text-right"},[_c('h3',{staticClass:"price-normal-text"},[_vm._v(" ฿"+_vm._s(_vm._f("currency2")(_vm.advancePaymentOptional))+" ")])])],1):_vm._e(),(_vm.isDeposit)?_c('v-row',_vm._b({},'v-row',{
            justify: 'between',
            align: 'center',
          },false),[_c('v-col',{attrs:{"cols":"auto"}},[_c('h3',[_vm._v("ยอดมัดจำ (ชำระแล้ว)")])]),_c('v-col',{staticClass:"text-right"},[_c('h3',{staticClass:"price-normal-text"},[_vm._v(" ฿"+_vm._s(_vm._f("currency2")(_vm.depositAmount))+" ")])])],1):_vm._e(),(_vm.isDeposit && _vm.finalPrice > 0)?_c('v-row',_vm._b({},'v-row',{
            justify: 'between',
            align: 'center',
          },false),[_c('v-col',[_c('h3',[_vm._v("ยอดชำระคงเหลือ")])]),_c('v-col',{staticClass:"text-right"},[_c('h3',{staticClass:"price-summary-text"},[_vm._v(" ฿"+_vm._s(_vm._f("currency2")(_vm.finalPriceDeposit))+" ")])])],1):_vm._e(),(_vm.isShowShipping)?[_c('hr',{staticClass:"my-3 gray"}),(_vm.isDelivery)?[_c('v-row',[_c('v-col',[_c('h3',[_vm._v(_vm._s(_vm.fullName))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"mb-2"},[_vm._v("จัดส่งตามที่อยู่")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.fullAddress)}})]),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"mb-2"},[_vm._v("จะได้รับสินค้าภายในวันที่")]),_vm._v(" "+_vm._s(_vm.data.shipping_date || '-')+" ")])],1)]:[_c('v-row',[_c('v-col',[_c('h3',[_vm._v("ชำระและรับสินค้าได้ที่สาขา")])])],1),_c('v-row',[_c('v-col',[_c('div',[_c('span',[_c('b',[_vm._v("สาขา:")]),_vm._v(" "+_vm._s(_vm.receiveBranchName)+" ")])]),_c('div',[_c('span',[_c('b',[_vm._v("วันที่:")]),_vm._v(" "+_vm._s(_vm.shippingDate)+" ")])])])],1),_c('v-row',[_c('v-col',{staticClass:"text-red"},[_vm._v(" *การจองและรับสินค้าที่สาขา บริษัทฯ ขอเช็คเปิดเครื่องเพื่อการรับประกันสินค้าทุกกรณี และขอสงวนสิทธิ์ยกเลิกคำสั่งซื้อหากลูกค้าไม่ประสงค์จะเปิดเช็คเครื่อง ")])],1)]]:_vm._e()]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }