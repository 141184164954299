var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.isLoading)?_c('Load'):_vm._e(),(_vm.order.data)?[(_vm.isBnn)?_c('div',{staticClass:"full-page content-center text-dark-gray pt-4 pt-md-8"},[_c('v-container',[(_vm.order.data.length === 0)?_c('Wording',{attrs:{"image":require('@/assets/icon/fail.svg'),"color":"red","title":"ไม่พบเลขที่ใบจองหรือทำรายการไม่สำเร็จ","dashed":"","showRefId":"","desc":"กรุณาสั่งซื้อสินค้าอีกครั้ง","desc2":"หากคุณพบปัญหากรุณาสั่งซื้ออีกครั้ง หรือติดต่อเจ้าหน้าที่ (fb)"}}):_vm._e(),(
            _vm.order.data.length !== 0 &&
              _vm.order.data.status === _vm.ORDER_STATUS.COMPLETE
          )?[(_vm.order.data.payment_is_deposit)?_c('Wording',{attrs:{"image":require('@/assets/icon/success.svg'),"title":"ยืนยันการชำระเงิน","refId":_vm.order.data.ref_id,"amount":_vm.order.data.total_deposit,"depositAmount":_vm.order.data.product_price_deposit,"totalAmount":_vm.totalPriceDeposit,"descTotalAmount":_vm.order.data.status_2nd === _vm.ORDER_STATUS.COMPLETE
                ? ''
                : '(รอดำเนินการ)',"status2nd":_vm.order.data.status_2nd,"depositNumber":_vm.depositNumber,"color":"green","isDeposit":"","dashed":"","showRefId":"","desc":"ระบบได้รับการชำระเงินแล้ว","desc2":"รอรับรายละเอียดได้ทางอีเมลของคุณ"}}):_c('Wording',{attrs:{"image":require('@/assets/icon/success.svg'),"title":"ยืนยันการชำระเงิน","refId":_vm.order.data.ref_id,"amount":_vm.amount,"color":"green","dashed":"","showRefId":"","desc":"ระบบได้รับการชำระเงินแล้ว","desc2":"รอรับรายละเอียดได้ทางอีเมลของคุณ"}})]:_vm._e(),(
            _vm.order.data.length !== 0 &&
              _vm.order.data.status === _vm.ORDER_STATUS.OK
          )?_c('Wording',{attrs:{"image":require('@/assets/icon/success.svg'),"title":"ชำระเงินมัดจำสำเร็จ","refId":_vm.order.data.ref_id,"depositNumber":_vm.depositNumber,"color":"black","dashed":"","showRefId":"","desc":"โปรดชำระยอดที่เหลือได้ที่สาขาที่ท่านเลือก","desc2":"รอรับรายละเอียดได้ทางอีเมลของคุณ"}}):_vm._e(),(
            _vm.order.data.length !== 0 &&
              _vm.order.data.status === _vm.ORDER_STATUS.CREATE
          )?_c('Wording',{attrs:{"image":require('@/assets/icon/process.svg'),"title":"กำลังดำเนินการ","refId":_vm.order.data.ref_id,"color":"black","dashed":"","showRefId":"","desc":"ระบบได้รับคำสั่งซื้อ หากคุณได้ชำระค่าสินค้าแล้ว กรุณารอการติดต่อกลับไป","desc2":"หากคุณพบปัญหากรุณาสั่งซื้ออีกครั้ง หรือติดต่อเจ้าหน้าที่ (fb)"}}):_vm._e(),(
            _vm.order.data.length !== 0 &&
              _vm.order.data.status === _vm.ORDER_STATUS.PROCESS
          )?[(_vm.order.data.is_pay_at_store)?_c('FlashMessagePayAtStore',{attrs:{"image":require('@/assets/icon/alarm.svg'),"refId":_vm.order.data.ref_id || _vm.order.data.order,"showRefId":true}}):_c('Wording',{attrs:{"image":require('@/assets/icon/process.svg'),"title":"กำลังดำเนินการ","refId":_vm.order.data.ref_id,"color":"black","dashed":"","showRefId":"","desc":"ระบบได้รับคำสั่งซื้อ หากคุณได้ชำระค่าสินค้าแล้ว กรุณารอการติดต่อกลับไป","desc2":"หากคุณพบปัญหากรุณาสั่งซื้ออีกครั้ง หรือติดต่อเจ้าหน้าที่ (fb)"}})]:_vm._e(),(
            _vm.order.data.length !== 0 &&
              _vm.order.data.status === _vm.ORDER_STATUS.SUCCESS
          )?[(
              _vm.order.data.itec_sale_order_status ===
                _vm.ORDER_STATUS.OK && _vm.order.data.payment_is_deposit
            )?_c('Wording',{attrs:{"image":require('@/assets/icon/process.svg'),"title":"กำลังดำเนินการ","refId":_vm.order.data.ref_id,"color":"green","dashed":"","showRefId":"","desc":"กำลังดำเนินการชำระเงินส่วนที่เหลือ","desc2":"เราจะส่งอีเมล์และรายละเอียดให้กับคุณทางอีเมล์ ขอขอบพระคุณ"}}):(
              _vm.order.data.itec_sale_order_status ===
                _vm.ORDER_STATUS.OK &&
                _vm.order.data.status_2nd === _vm.ORDER_STATUS.COMPLETE
            )?_c('Wording',{attrs:{"image":require('@/assets/icon/success.svg'),"title":"ทำรายการสำเร็จ","refId":_vm.order.data.ref_id,"amount":_vm.amount,"color":"green","dashed":"","showRefId":"","desc":"ระบบได้รับการชำระเงินแล้ว","desc2":"เราจะส่งอีเมล์และรายละเอียดให้กับคุณทางอีเมล์ ขอขอบพระคุณ"}}):_c('Wording',{attrs:{"image":require('@/assets/icon/success.svg'),"title":"ทำรายการสำเร็จ","refId":_vm.order.data.ref_id,"amount":_vm.amount,"color":"green","dashed":"","showRefId":"","desc":"ระบบได้รับการชำระเงินแล้ว","desc2":"เราจะส่งอีเมล์และรายละเอียดให้กับคุณทางอีเมล์ ขอขอบพระคุณ"}})]:_vm._e(),(
            _vm.order.data.length !== 0 &&
              _vm.order.data.status === _vm.ORDER_STATUS.CANCEL
          )?_c('Wording',{attrs:{"image":require('@/assets/icon/fail.svg'),"title":"ระบบทำการยกเลิกรายการแล้ว","refId":_vm.order.data.ref_id,"color":"red","dashed":"","desc":"รายการนี้ได้ถูกยกเลิกแล้ว","desc2":"หากคุณพบปัญหากรุณาสั่งซื้ออีกครั้ง หรือติดต่อเจ้าหน้าที่ (fb)"}}):_vm._e(),(
            _vm.order.data.status === _vm.ORDER_STATUS.COMPLETE ||
              _vm.isProcessingPayAtStore
          )?_c('div',{staticClass:"w-full pt-6 pt-md-8"},[_c('OrderDetail',{attrs:{"isShowShipping":_vm.isProcessingPayAtStore ? false : true}})],1):_vm._e()],2)],1):[_c('OrderDetailStudio7')]]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }