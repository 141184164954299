<template>
  <section>
    <Load v-if="isLoading" />

    <template v-if="order.data">
      <div
        v-if="isBnn"
        class="full-page content-center text-dark-gray pt-4 pt-md-8"
      >
        <v-container>
          <Wording
            v-if="order.data.length === 0"
            :image="require('@/assets/icon/fail.svg')"
            color="red"
            title="ไม่พบเลขที่ใบจองหรือทำรายการไม่สำเร็จ"
            dashed
            showRefId
            desc="กรุณาสั่งซื้อสินค้าอีกครั้ง"
            desc2="หากคุณพบปัญหากรุณาสั่งซื้ออีกครั้ง หรือติดต่อเจ้าหน้าที่ (fb)"
          />

          <template
            v-if="
              order.data.length !== 0 &&
                order.data.status === ORDER_STATUS.COMPLETE
            "
          >
            <Wording
              v-if="order.data.payment_is_deposit"
              :image="require('@/assets/icon/success.svg')"
              title="ยืนยันการชำระเงิน"
              :refId="order.data.ref_id"
              :amount="order.data.total_deposit"
              :depositAmount="order.data.product_price_deposit"
              :totalAmount="totalPriceDeposit"
              :descTotalAmount="
                order.data.status_2nd === ORDER_STATUS.COMPLETE
                  ? ''
                  : '(รอดำเนินการ)'
              "
              :status2nd="order.data.status_2nd"
              :depositNumber="depositNumber"
              color="green"
              isDeposit
              dashed
              showRefId
              desc="ระบบได้รับการชำระเงินแล้ว"
              desc2="รอรับรายละเอียดได้ทางอีเมลของคุณ"
            />
            <Wording
              v-else
              :image="require('@/assets/icon/success.svg')"
              title="ยืนยันการชำระเงิน"
              :refId="order.data.ref_id"
              :amount="amount"
              color="green"
              dashed
              showRefId
              desc="ระบบได้รับการชำระเงินแล้ว"
              desc2="รอรับรายละเอียดได้ทางอีเมลของคุณ"
            />
          </template>

          <Wording
            v-if="
              order.data.length !== 0 &&
                order.data.status === ORDER_STATUS.OK
            "
            :image="require('@/assets/icon/success.svg')"
            title="ชำระเงินมัดจำสำเร็จ"
            :refId="order.data.ref_id"
            :depositNumber="depositNumber"
            color="black"
            dashed
            showRefId
            desc="โปรดชำระยอดที่เหลือได้ที่สาขาที่ท่านเลือก"
            desc2="รอรับรายละเอียดได้ทางอีเมลของคุณ"
          />

          <Wording
            v-if="
              order.data.length !== 0 &&
                order.data.status === ORDER_STATUS.CREATE
            "
            :image="require('@/assets/icon/process.svg')"
            title="กำลังดำเนินการ"
            :refId="order.data.ref_id"
            color="black"
            dashed
            showRefId
            desc="ระบบได้รับคำสั่งซื้อ หากคุณได้ชำระค่าสินค้าแล้ว กรุณารอการติดต่อกลับไป"
            desc2="หากคุณพบปัญหากรุณาสั่งซื้ออีกครั้ง หรือติดต่อเจ้าหน้าที่ (fb)"
          />

          <template
            v-if="
              order.data.length !== 0 &&
                order.data.status === ORDER_STATUS.PROCESS
            "
          >
            <FlashMessagePayAtStore
              v-if="order.data.is_pay_at_store"
              :image="require('@/assets/icon/alarm.svg')"
              :refId="order.data.ref_id || order.data.order"
              :showRefId="true"
            />
            <Wording
              v-else
              :image="require('@/assets/icon/process.svg')"
              title="กำลังดำเนินการ"
              :refId="order.data.ref_id"
              color="black"
              dashed
              showRefId
              desc="ระบบได้รับคำสั่งซื้อ หากคุณได้ชำระค่าสินค้าแล้ว กรุณารอการติดต่อกลับไป"
              desc2="หากคุณพบปัญหากรุณาสั่งซื้ออีกครั้ง หรือติดต่อเจ้าหน้าที่ (fb)"
            />
          </template>

          <template
            v-if="
              order.data.length !== 0 &&
                order.data.status === ORDER_STATUS.SUCCESS
            "
          >
            <Wording
              v-if="
                order.data.itec_sale_order_status ===
                  ORDER_STATUS.OK && order.data.payment_is_deposit
              "
              :image="require('@/assets/icon/process.svg')"
              title="กำลังดำเนินการ"
              :refId="order.data.ref_id"
              color="green"
              dashed
              showRefId
              desc="กำลังดำเนินการชำระเงินส่วนที่เหลือ"
              desc2="เราจะส่งอีเมล์และรายละเอียดให้กับคุณทางอีเมล์ ขอขอบพระคุณ"
            />
            <Wording
              v-else-if="
                order.data.itec_sale_order_status ===
                  ORDER_STATUS.OK &&
                  order.data.status_2nd === ORDER_STATUS.COMPLETE
              "
              :image="require('@/assets/icon/success.svg')"
              title="ทำรายการสำเร็จ"
              :refId="order.data.ref_id"
              :amount="amount"
              color="green"
              dashed
              showRefId
              desc="ระบบได้รับการชำระเงินแล้ว"
              desc2="เราจะส่งอีเมล์และรายละเอียดให้กับคุณทางอีเมล์ ขอขอบพระคุณ"
            />
            <Wording
              v-else
              :image="require('@/assets/icon/success.svg')"
              title="ทำรายการสำเร็จ"
              :refId="order.data.ref_id"
              :amount="amount"
              color="green"
              dashed
              showRefId
              desc="ระบบได้รับการชำระเงินแล้ว"
              desc2="เราจะส่งอีเมล์และรายละเอียดให้กับคุณทางอีเมล์ ขอขอบพระคุณ"
            />
          </template>

          <Wording
            v-if="
              order.data.length !== 0 &&
                order.data.status === ORDER_STATUS.CANCEL
            "
            :image="require('@/assets/icon/fail.svg')"
            title="ระบบทำการยกเลิกรายการแล้ว"
            :refId="order.data.ref_id"
            color="red"
            dashed
            desc="รายการนี้ได้ถูกยกเลิกแล้ว"
            desc2="หากคุณพบปัญหากรุณาสั่งซื้ออีกครั้ง หรือติดต่อเจ้าหน้าที่ (fb)"
          />

          <div
            class="w-full pt-6 pt-md-8"
            v-if="
              order.data.status === ORDER_STATUS.COMPLETE ||
                isProcessingPayAtStore
            "
          >
            <OrderDetail
              :isShowShipping="isProcessingPayAtStore ? false : true"
            />
          </div>
        </v-container>
      </div>

      <template v-else>
        <OrderDetailStudio7 />
      </template>
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Wording from '@/components/Wording'
import OrderDetail from '@/components/bnn/OrderDetail'
import { ORDER_STATUS } from '@/config/order'
import isBnn from '@/config/isBnn'

export default {
  name: 'Success',

  computed: {
    ...mapState({
      order: state => state.order,
      isLoading: state => state.order.isLoading,
      isPayAtStoreExpired: state => state.order.isExpired,
    }),

    amount() {
      if (this.order?.data?.paid_amount_2nd) {
        return this.order?.data?.paid_amount_2nd
      }
      return this.order.data?.total_deposit
    },

    depositNumber() {
      return this.order?.data?.deposit_number ?? 'กำลังดำเนินการ'
    },

    totalPriceDeposit() {
      const total =
        this.order?.data?.status_2nd === this.ORDER_STATUS.COMPLETE
          ? parseFloat(this.order?.data?.paid_amount_2nd) ||
            parseFloat(this.order?.data?.total_price)
          : parseFloat(this.order?.data?.total_price)

      const discount = parseFloat(this.order?.data?.total_deposit)

      return total - discount ?? 0
    },

    isProcessingPayAtStore() {
      return (
        this.order?.data?.status === this.ORDER_STATUS.PROCESS &&
        this.order?.data?.is_pay_at_store
      )
    },
  },

  data() {
    return {
      timer: null,
      ORDER_STATUS,
      isBnn,
    }
  },

  components: {
    Wording,
    OrderDetail,
    FlashMessagePayAtStore: () =>
      import('@/components/bnn/FlashMessagePayAtStore'),
    Load: () => import('@/views/Load'),
    OrderDetailStudio7: () =>
      import('@/components/studio7/OrderDetailStudio7'),
  },

  watch: {
    order: {
      handler() {
        this.setLatestSlug()

        const { CREATE, PROCESS } = this.ORDER_STATUS

        /**
         * Checks the status of the order and cancels the auto-update if necessary.
         * The auto-update is cancelled if the order status is not 'CREATE' or 'PROCESS',
         * or if the payment is being processed at the store.
         */

        if (
          this.order.data.status &&
          ![CREATE, PROCESS].includes(this.order.data.status)
          // || (this.isProcessingPayAtStore && !this.isPayAtStoreExpired)
        ) {
          this.cancelAutoUpdate()
        }
      },
      deep: true,
    },
    isPayAtStoreExpired(val, oldVal) {
      if (val && val !== oldVal) {
        this.timer = setInterval(this.refetch, 60000)
      }
    },
  },

  mounted() {
    this.sentEventGoogleTAG()
  },

  created() {
    this.refetch()
    this.timer = setInterval(this.refetch, 60000)
  },

  beforeUnmount() {
    this.cancelAutoUpdate()
  },

  beforeDestroy() {
    this.cancelAutoUpdate()
  },

  methods: {
    setLatestSlug() {
      if (this.order?.data?.campaign?.slug) {
        localStorage.setItem(
          'productLatestVisit',
          this.order.data.campaign.slug,
        )
      }
    },

    async refetch() {
      const uid = this.$route.params.uid
      if (uid) {
        try {
          await this.$store.dispatch('order/getOrder', uid)
        } catch {
          this.cancelAutoUpdate()

          //   this.$router.push({
          //     name: 'Error',
          //     params: { error: 'notfound' },
          //   })
        }
      }
      this.$forceUpdate()
    },

    cancelAutoUpdate() {
      clearInterval(this.timer)
    },

    sentEventGoogleTAG() {
      try {
        window.dataLayer.push({
          event: 'Thank You',
          page: 'thank',
          data: {
            full_name: this.order.data.full_name,
            sku: this.order.data.sku,
            status: this.order.data.status,
            total_deposit: this.order.data.total_deposit,
            total_price: this.order.data.total_price,
            bundle_products: this.order.data.bundle_products,
          },
        })
      } catch (e) {
        return
      }
    },
  },
}
</script>

<style lang="stylus">

.h-full {
  margin-top: 80px;
  min-height: 100dhv;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hr-dash {
  overflow: hidden;
  position: relative;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  width: 50%;
}

.hr-dash:before {
  content: '';
  position: absolute;
  border-bottom: 5px dashed $color-gray-200;
  top: -3px;
  bottom: -3px;
  left: -3px;
  right: -3px;
}
</style>
