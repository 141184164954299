export const ORDER_STATUS = Object.freeze({
  CREATE: 'create',
  PROCESS: 'process',
  COMPLETE: 'complete',
  SUCCESS: 'success',
  CANCEL: 'cancel',
  OK: 'ok',
  EXPIRED: 'expired',
})

export default ORDER_STATUS
