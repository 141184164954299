<template>
  <div>
    <v-row>
      <v-col v-if="productImage" sm="6" cols="12">
        <div class="product-image">
          <img
            :src="productImage"
            :alt="productName"
            class="product-image-item"
          />
        </div>
      </v-col>
      <v-col sm="6" cols="12" class="cart-order">
        <v-row
          v-bind="{
            justify: 'center',
            align: 'start',
            'no-gutters': true,
          }"
        >
          <v-col>
            <h3>{{ productName }}</h3>
            <div>
              SKU: <span>{{ productSKU }}</span>
            </div>
          </v-col>
          <v-col cols="auto" class="text-right price-text font-bold">
            ฿{{ productPrice | currency2 }}
          </v-col>
        </v-row>

        <hr v-if="bundles.length > 0" class="my-3 gray" />

        <v-row
          v-if="bundles.length > 0"
          v-bind="{
            justify: 'between',
            align: 'center',
          }"
        >
          <v-col>
            <!-- <h2>อุปกรณ์เสริมที่ซื้อพร้อมกัน</h2> -->
            <h3>รายการสินค้าเพิ่มเติม</h3>
          </v-col>
        </v-row>
        <v-row
          v-for="(item, index) in bundles"
          :key="index"
          v-bind="{
            justify: 'between',
          }"
          class="bundle-list"
        >
          <v-col cols="8">
            <div class="sku-product">
              <img :src="item.image_url" />
              <div>{{ item.name }}</div>
            </div>
          </v-col>
          <template v-if="+item.price_srp === 0">
            <v-col class="price-bundle-text font-bold free">
              <div>ฟรี</div>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="4" class="price-bundle-text font-bold">
              <div v-if="isDelivery">
                ฿{{ item.price_ship | currency2 }}
              </div>
              <div v-else>฿{{ item.price_store | currency2 }}</div>
              <div
                v-if="
                  isDelivery && item.price_ship !== item.price_srp
                "
                class="text-line-through normal-price"
              >
                ฿{{ item.price_srp | currency2 }}
              </div>
              <div
                v-else-if="
                  !isDelivery && item.price_store !== item.price_srp
                "
                class="text-line-through normal-price"
              >
                ฿{{ item.price_srp | currency2 }}
              </div>
            </v-col>
          </template>
        </v-row>

        <template v-if="isShowPayment">
          <hr class="my-3 gray" />

          <v-row
            v-if="!isDeposit"
            v-bind="{
              justify: 'between',
              align: 'center',
            }"
          >
            <v-col>
              <h3>ยอดรวมสุทธิ</h3>
            </v-col>
            <v-col class="text-right">
              <h3 class="price-normal-text">
                ฿{{ finalPrice | currency2 }}
              </h3>
            </v-col>
          </v-row>

          <v-row
            v-if="discountAmount > 0"
            v-bind="{
              justify: 'between',
              align: 'center',
            }"
          >
            <v-col>
              <h3>ส่วนลด</h3>
            </v-col>
            <v-col class="text-right">
              <h3 class="price-normal-text">
                - ฿{{ discountAmount | currency2 }}
              </h3>
            </v-col>
          </v-row>

          <v-row
            v-if="additionalDiscount > 0"
            v-bind="{
              justify: 'between',
              align: 'center',
            }"
          >
            <v-col>
              <h3>
                {{ additionalDiscountLabel }}
              </h3>
            </v-col>
            <v-col class="text-right">
              <h3 class="price-normal-text">
                - ฿{{ additionalDiscount | currency2 }}
              </h3>
            </v-col>
          </v-row>

          <v-row
            v-if="couponCode"
            v-bind="{
              justify: 'between',
              align: 'center',
            }"
          >
            <v-col>
              <h3>คูปองที่ใช้</h3>
            </v-col>
            <v-col class="text-right">
              <h3 class="badge-coupon">
                {{ couponCode }}
              </h3>
            </v-col>
          </v-row>

          <v-row
            v-if="advancePaymentOptional > 0"
            v-bind="{
              justify: 'between',
              align: 'center',
            }"
          >
            <v-col>
              <h3>
                ชำระเพิ่มเติม
              </h3>
            </v-col>
            <v-col class="text-right">
              <h3 class="price-normal-text">
                ฿{{ advancePaymentOptional | currency2 }}
              </h3>
            </v-col>
          </v-row>

          <v-row
            v-if="isDeposit"
            v-bind="{
              justify: 'between',
              align: 'center',
            }"
          >
            <v-col cols="auto">
              <h3>ยอดมัดจำ (ชำระแล้ว)</h3>
            </v-col>
            <v-col class="text-right">
              <h3 class="price-normal-text">
                ฿{{ depositAmount | currency2 }}
              </h3>
            </v-col>
          </v-row>
          <v-row
            v-if="isDeposit && finalPrice > 0"
            v-bind="{
              justify: 'between',
              align: 'center',
            }"
          >
            <v-col>
              <h3>ยอดชำระคงเหลือ</h3>
            </v-col>
            <v-col class="text-right">
              <h3 class="price-summary-text">
                ฿{{ finalPriceDeposit | currency2 }}
              </h3>
            </v-col>
          </v-row>
          <template v-if="isShowShipping">
            <hr class="my-3 gray" />

            <template v-if="isDelivery">
              <v-row>
                <v-col>
                  <h3>{{ fullName }}</h3>
                </v-col>
                <v-col cols="12">
                  <h3 class="mb-2">จัดส่งตามที่อยู่</h3>
                  <span v-html="fullAddress"></span>
                </v-col>
                <v-col cols="12">
                  <h3 class="mb-2">จะได้รับสินค้าภายในวันที่</h3>
                  {{ data.shipping_date || '-' }}
                </v-col>
              </v-row>
            </template>

            <template v-else>
              <v-row>
                <v-col>
                  <h3>ชำระและรับสินค้าได้ที่สาขา</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div>
                    <span>
                      <b>สาขา:</b> {{ receiveBranchName }}
                    </span>
                  </div>
                  <div>
                    <span> <b>วันที่:</b> {{ shippingDate }} </span>
                  </div>
                  <!-- ? Notes: Hide this -->
                  <!-- <div>
                  <span> <b>เวลา:</b> {{ receiveTime }} </span>
                </div> -->
                </v-col>
              </v-row>
              <v-row>
                <v-col class="text-red">
                  *การจองและรับสินค้าที่สาขา บริษัทฯ
                  ขอเช็คเปิดเครื่องเพื่อการรับประกันสินค้าทุกกรณี
                  และขอสงวนสิทธิ์ยกเลิกคำสั่งซื้อหากลูกค้าไม่ประสงค์จะเปิดเช็คเครื่อง
                </v-col>
              </v-row>
            </template>
          </template>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { resizeImage } from '@/utils/helpers'

export default {
  props: {
    isShowShipping: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapState({
      data: state => state.order.data,
    }),

    productName() {
      return this.data?.product_main?.name ?? ''
    },

    productPrice() {
      return this.data?.product_main?.price ?? 0
    },

    productSKU() {
      return this.data?.product_main?.sku ?? ''
    },

    productImage() {
      let image = this.data?.product_main?.image_url
        ? resizeImage(this.data?.product_main?.image_url, 1000)
        : ''

      return image
    },

    validCoupon() {
      return !this.coupon.isError && this.coupon.code
    },

    bundles() {
      return (
        this.data?.bundle_products?.filter(
          i => i?.type !== 'delivery',
        ) ?? []
      )
    },

    isShowPayment() {
      return true
      // return (
      //   this.data?.is_deposit &&
      //   this.data?.is_delivery &&
      //   !this.data?.status_2nd
      // )
    },

    fullName() {
      return this.data?.full_name ?? ''
    },

    fullAddress() {
      return `บ้าน/อาคาร เลขที่ ${this.data?.address} แขวง/ตำบล ${this.data?.sub_district} เขต/อำเภอ ${this.data?.district} จังหวัด ${this.data?.province} รหัสไปรษณีย์ ${this.data?.postcode}`
    },

    receiveBranchName() {
      return this.data?.receive_branch_name ?? ''
    },

    shippingDate() {
      return (
        this.data?.shipping_date ??
        this.data?.receive_date ??
        'ไม่พบข้อมูล'
      )
    },

    receiveDate() {
      return this.data?.receive_date ?? ''
    },

    receiveTime() {
      return this.data?.receive_time ?? ''
    },

    status2nd() {
      return this.data?.status_2nd
    },

    isDelivery() {
      return this.data?.is_delivery
    },

    isDeposit() {
      return this.data?.payment_is_deposit
    },

    depositAmount() {
      return this.data?.total_deposit ?? 0
    },

    finalPrice() {
      return this.isPayAtStoreTradeIn || this.isPayAtStorePromotion
        ? this.data?.pay_at_store_detail?.paid_amount ?? 0
        : this.data?.total_price ?? 0
    },

    discountAmount() {
      return this.data?.discount ?? 0
    },

    remainingAmount() {
      return this.data?.remaining ?? 0
    },

    finalPriceDeposit() {
      const total =
        this.data?.status_2nd === 'complete'
          ? parseFloat(this.data?.paid_amount_2nd) ||
            parseFloat(this.data?.total_price)
          : parseFloat(this.data?.total_price)

      const discount = parseFloat(this.data?.total_deposit)

      return total - discount ?? 0
    },

    couponCode() {
      return this.data?.coupon_code ?? ''
    },

    isPayAtStoreTradeIn() {
      return (
        this.data?.pay_at_store_detail?.order_type == 'with_tradein'
      )
    },

    isPayAtStorePromotion() {
      return this.data?.pay_at_store_detail?.order_type == 'with_plan'
    },

    additionalDiscountLabel() {
      return this.isPayAtStoreTradeIn
        ? 'ส่วนลดจาก Trade-in'
        : this.isPayAtStorePromotion
        ? 'ส่วนลดจากโปรโมชั่นเบอร์ทรู'
        : 'ส่วนลดเพิ่มเติม'
    },

    additionalDiscount() {
      return this.isPayAtStoreTradeIn
        ? Number(this.data?.pay_at_store_detail?.voucher_amount)
        : this.isPayAtStorePromotion
        ? // ? this.data?.pay_at_store_detail?.promotion_discount
          this.data?.pay_at_store_detail?.with_plan_promotion_discount
        : 0
    },

    advancePayment() {
      return this.data?.pay_at_store_detail?.advance_payment ?? 0
    },

    advancePaymentOptional() {
      return this.data?.pay_at_store_detail?.advance_payment ?? 0
    },
  },

  components: {},
}
</script>

<style lang="stylus" scoped>

.coupon-code
    display: flex
    justify-content: center
    align-items: center
    gap: 0 16px
    .input-coupon-code
        width:70%
    .use-code
        width: 30%

.bundle-list
    font-size: 0.95rem

.price-text
    font-size: 1.15rem
    color:  $color-text-price
    text-align: right

.price-bundle-text
    color:  $color-text-price
    text-align: right
    display: flex
    flex-direction: column
    justify-content: flex-start

.price-bundle-text.free
	color: #4CAF50;

.price-normal-text
    color:  $color-normal-price

.price-summary-text
    color:  $color-text-price

.payment-type-text
    color: $color-bnn-orange

.text-red
    color: $color-bnn-red-2

.sku-product
  display: flex
  gap 0 16px
  align-items: flex-start
  img
    width: 48px
    height: 48px
    object-fit: contain

.coupon-msg-error
  color: $color-bnn-red
  margin-top: -1rem
  font-size: 1rem

.normal-price
  color: $color-bnn-gray-medium-2
  font-size: 0.85rem
  text-align: right

.product-image
  width: 100%
  height: fit-content
  max-width: 100%
  max-height: 100%
  display: flex
  aspect-ratio: 1/1
  .product-image-item
    width: 100%
    height: 100%
    max-width: 100%
    max-height: 100%
    object-fit: contain
    object-position: top center
</style>
