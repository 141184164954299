<template>
  <div class="w-full">
    <v-row v-bind="{ justify: 'center' }" class="mb-8">
      <img class="icon img-end" :src="image" />
    </v-row>
    <v-row
      v-bind="{ justify: 'center' }"
      class="align-item item-center mb-4"
    >
      <h3
        class="text-3xl text-center"
        v-bind:class="{
          'text-red': color === 'red',
          '': color === 'black',
          'text-green': color === 'green',
        }"
      >
        {{ title }}
      </h3>
    </v-row>
    <v-row
      v-if="refId && showRefId"
      v-bind="{ justify: 'center', 'no-gutters': true }"
    >
      <span class="text-xl font-semibold"
        >เลขที่ใบจอง: {{ refId }}</span
      ></v-row
    >
    <v-row
      v-if="isDeposit"
      v-bind="{ justify: 'center', 'no-gutters': true }"
    >
      <span class="text-xl font-semibold mb-4"
        >เลขที่ใบรับของ (บิลมัดจำ) : {{ depositNumber }}</span
      ></v-row
    >
    <template v-if="isDeposit">
      <!-- <v-row v-if="depositAmount > 0" v-bind="{ justify: 'center', 'no-gutters': true }">
        <span
          class="text-xl font-semibold text-center"
          id="depositAmount"
          >ยอดมัดจำ</span
        ></v-row
      > -->
      <v-row
        v-if="amount > 0"
        v-bind="{ justify: 'center', 'no-gutters': true }"
      >
        <span
          class="text-xl mb-4 font-semibold text-center"
          id="amount"
          >ยอดมัดจำ: ฿{{ amount | currency2 }}</span
        ></v-row
      >
      <v-row
        v-if="totalAmount > 0"
        v-bind="{ justify: 'center', 'no-gutters': true }"
      >
        <span
          v-if="status2nd === 'complete'"
          class="text-xl mb-4 font-semibold text-center"
          id="totalAmount"
          >ยอดชำระรอบ 2: ฿{{ totalAmount | currency2 }}
          {{ descTotalAmount }}</span
        >
        <span v-else class="text-xl mb-4 text-center" id="totalAmount"
          >ยอดชำระคงเหลือ: ฿{{ totalAmount | currency2 }}
          {{ descTotalAmount }}</span
        >
      </v-row>
    </template>
    <template v-else>
      <v-row
        v-if="amount > 0"
        v-bind="{ justify: 'center', 'no-gutters': true }"
      >
        <span class="text-xl font-semibold text-center" id="amount"
          >ยอดที่ชำระ: ฿{{ amount | currency2 }}</span
        ></v-row
      >
    </template>
    <v-row
      v-if="dashed"
      v-bind="{ justify: 'center', 'no-gutters': true }"
    >
      <div class="hr-dash"></div>
    </v-row>
    <v-row v-bind="{ justify: 'center' }" class="mb-2">
      <span class="text-lg text-center">{{ desc }}</span>
    </v-row>
    <v-row
      v-if="desc2"
      v-bind="{ justify: 'center', 'no-gutters': true }"
    >
      <span class="text-lg text-center"
        ><div v-html="getDesc2(desc2)"></div
      ></span>
    </v-row>
  </div>
</template>

<script>
import isBnn from '@/config/isBnn'

export default {
  name: 'Wording',
  props: {
    image: String,
    color: String,
    title: String,
    refId: String,
    showRefId: Boolean,
    desc: String,
    desc2: String,
    dashed: Boolean,
    descTotalAmount: String,
    status2nd: String,
    totalAmount: {
      type: Number,
      default: 0,
    },
    depositAmount: {
      type: Number,
      default: 0,
    },
    amount: {
      type: Number,
      default: 0,
    },
    isDeposit: {
      type: Boolean,
      default: false,
    },
    depositNumber: String,
  },
  methods: {
    getDesc2(value) {
      const regex = /ติดต่อเจ้าหน้าที่ \(fb\)/gm
      if (value.indexOf('ติดต่อเจ้าหน้าที่ (fb)') > -1) {
        const html = isBnn
          ? `<a class="d-inline-flex align-flex-end ml-2" href="https://www.facebook.com/Bananaitshop/" target="_blank">ติดต่อเจ้าหน้าที่ <img width="28px" height="24px" src="${require('@/assets/facebook-logo.png')}" class="ml-2"/></a>`
          : `<a class="d-inline-flex align-flex-end ml-2" href="https://www.facebook.com/Studio7Thailand/" target="_blank">ติดต่อเจ้าหน้าที่ <img width="24px" height="24px" src="${require('@/assets/facebook-logo.png')}" class="ml-2"/></a>`
        return value.replace(regex, html)
      }
      return value
    },
  },
}
</script>

<style lang="stylus">
@import '../assets/css/style.styl'
.img-end
  max-width 64px
</style>
