// Check campaign name
import imageCfUrl from '@/config/imageCfUrl'

const listHide = [
  //   'iphone-14',
  //   'iphone-15',
  'pre-order-iphone-15-series',
]

export const isShowDelivery = campaignSlug => {
  // check contain iPhone 14,iPhone 15
  //   console.log(campaignSlug)
  if (listHide.includes(campaignSlug.toLowerCase())) {
    return false
  }
  // because selected from checkout is delivery or pickup in store
  return true
}

export const resizeImage = (url, width = 1000) => {
  return `${imageCfUrl}width=${width}/${url}`
}

/**
 * Validate thai id
 * @param {String} idCard
 * @returns {Boolean}
 */
export const validateIdCard = idCard => {
  if (
    !idCard.match(/^[0-9]{1}-[0-9]{4}-[0-9]{5}-[0-9]{2}-[0-9]{1}$/)
  ) {
    return false
  }

  idCard = idCard.replace(/-/g, '')

  if (typeof idCard !== 'string') {
    return false
  }
  const m = idCard.match(/(\d{12})(\d)/)
  if (!m) {
    return false
  }

  const digits = m[1].split('')
  const sum = digits.reduce((total, digit, i) => {
    return total + (13 - i) * +digit
  }, 0)
  const lastDigit = `${(11 - (sum % 11)) % 10}`
  const inputLastDigit = m[2]

  if (lastDigit !== inputLastDigit) {
    return false
  }

  return true
}

/**
 * Validate passport
 * @param {String} text
 * @returns {Boolean}
 */
export const validatePassport = text => {
  if (typeof text !== 'string') {
    return false
  }

  const regex = /^[A-Za-z0-9]{6,12}$/

  if (!regex.test(text)) {
    return false
  }
  return true
}

/**
 * Validate start with 0
 * @param {String} value
 * @returns {Boolean}
 */

export const validateStartWithZero = value => {
  const regex = /^0+/
  return regex.test(value)
}
